import React from "react"
import Layout from "hoc/Layout"
import Hr from "components/Hr/Hr"
import Font42 from "components/Fonts/Font42"
import { Grid } from "@mui/material"
import Font24 from "components/Fonts/Font24"
import Font16 from "components/Fonts/Font16"

const Cookies = () => {
  const breadcrumbsData = [
    {
      title: "Startseite",
      link: "/",
    },
    "Cookies",
  ]
  return (
    <Layout breadcrumbsData={breadcrumbsData} seoTitle="Cookies">
      <Hr mb={4} mt={6} />
      <Font42 mb={4}>Cookies</Font42>
      <Grid container>
        <Grid mb={5}>
          <Font24 mb={3}>Cookie Einstellungen</Font24>
          <Font16 mb={3}>Wählen Sie Ihre Cookie Einstellungen</Font16>
          <Font16 mb={3}>
            Sie können jederzeit die Einstellungen für die auf diesem Endgerät
            genutzten Cookies nach Ihren Wünschen anpassen, indem Sie auf die
            Cookie-Einstellungen klicken.
          </Font16>
          <Font24 mb={3}>Dringend notwendig</Font24>

          <Font16 mb={2}>
            Hierbei handelt es sich um Cookies, die unbedingt notwendig für die
            Navigation auf der Webseite sind. Diese Cookies sind immer
            aktiviert, da sie für Grundfunktionen der Webseite erforderlich
            sind. Hierzu zählen Cookies, mit denen gespeichert werden kann, wo
            Sie sich auf der Webseite bewegen – während eines Besuchs oder,
            falls Sie es möchten, auch von einem Besuch zum nächsten. Zudem
            tragen sie zur sicheren und vorschriftsmäßigen Nutzung der Seite
            bei.
          </Font16>
        </Grid>
        <Grid mb={5}>
          <Font24 mb={3}>Statistik & Marketing</Font24>
          <Font16 mb={2}>
            Mithilfe dieser Cookies können wir den Datenverkehr und die
            Funktionalität von Webseiten messen, um Webseitendesigns sowie
            Inhalte zu testen und damit Ihr Benutzererlebnis auf dieser Webseite
            ständig zu verbessern. (z.B. Google Analytics)
          </Font16>
        </Grid>
        <Grid mb={5}>
          <Font24 mb={3}>Inhalte</Font24>
          <Font16 mb={2}>
            Die Webseite verwendet Inhalte von Drittanbietern, um die Inhalte
            interaktiver zu gestalten. Bei Deaktivierung dieser Einstellung,
            können diese nur noch eingeschränkt oder gar nicht genutzt werden.
            (z.B. Google Maps, YouTube Videos, Kontaktformular)
          </Font16>
        </Grid>
        <Grid mb={5}>
          <Font24 mb={3}>Einstellungen speichern</Font24>
        </Grid>
      </Grid>
    </Layout>
  )
}

export default Cookies
